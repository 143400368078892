@font-face {
  font-family: 'Pacaembu VF';
  src: url('pacaembuVF.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Pacaembu Grotesk VF';
  src: url('pacaembuGroteskVF.ttf') format('truetype-variations');
  font-weight: 1 999;
  font-style: normal;
  font-display: fallback;
}
